export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ciao i18n !!"])},
  "galleryTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["galleria"])},
  "aboutTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chi sono"])},
  "contactTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contatti"])},
  "helmetsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["caschi"])},
  "vehiclesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["veicoli"])},
  "panelsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pareti e pannelli"])},
  "othersTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["altri supporti"])},
  "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["visualizza"])},
  "buttonBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["indietro"])},
  "galleryText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divertiti a sfogliare alcuni tra i tantissimi lavori effettuati dagli anni 80 a oggi. Puoi trovare i disegni divisi in 4 sezioni in base al supporto su cui sono stati realizzati."])},
  "helmetsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalizzazione di caschi da sci, da ciclismo, moto e motocross"])},
  "vehiclesText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realizzazione di aerografie su moto, automobili, camion e molto altro"])},
  "panelsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disegni su pareti, pannelli, quadri ed insegne"])},
  "othersText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decorazioni su supporti di vario tipo come tele, cuoio e legno"])},
  "about": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mi presento..."])},
    "firstPart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono <text class='highlights'>Pietro Franzini</text> e fin da giovanissimo ho avuto una particolare attrazione per il disegno e la pittura. Desideroso di provare nuove tecniche artistiche mi sono avvicinato sempre di più al mondo dell'<text class='highlights'>aerografia</text>."])},
    "secondPart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La curiosità per questo strumento mi ha portato ad approfondire le mie conoscenze al riguardo. Autodidatta, ho realizzato i miei primi lavori a livello personale e, con gli anni, quello che inizialmente era solo un hobby si è trasformato in una vera e propria attività. Realizzo progetti su commissione che variano da decorazioni per ambienti pubblici o privati a personalizzazioni di veicoli e di oggetti vari."])},
    "thirdPart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I temi variano in base alle scelte del cliente, ma, in caso di incertezze, sarò lieto di proporvi alcune soluzioni. Attualmente risiedo a <text class='highlights'>Grosio</text> in Valtellina (SO), sempre disponibile per progetti e preventivi. Non vi resta che visitare la mia gallery ed eventualmente contattarmi.<p style='font-size: xx-large;'>Ciao!</p>"])}
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei interessato?"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiama o invia un'email e ti contatterò il prima possibile!"])},
    "write": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scrivimi ora"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "mex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messaggio"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["invia"])},
    "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviato"])}
  },
  "footer": {
    "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Franzdesign.eu, propietà di Franzdesign - Arredo Service di Pietro Franzini - P.IVA 00769360140"])},
    "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copyright ©2022 - Tutti i diritti riservati"])},
    "third": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sito realizzato da:"])}
  }
}