import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Gallery from '../views/Gallery.vue'
import About from '../views/About.vue'
import Contact from '../views/Contact.vue'
import Helmets from '../views/Helmets.vue'
import Vehicles from '../views/Vehicles.vue'
import Panels from '../views/Panels.vue'
import Others from '../views/Others.vue'
import NotFound from '../views/NotFound.vue'
import i18n from '../i18n'
import {h, resolveComponent} from 'vue'

if(localStorage.getItem('lang')) {
  i18n.global.locale.value = localStorage.getItem('lang')
}
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      redirect: `/${i18n.global.locale.value}`
    },
    {
      path: '/:lang',
      component: {
        render() {
          return h(resolveComponent('router-view'))
        }
      },
      children: [
        {
          path: '',
          name: 'Home',
          component: Home
        },
        {
          path: 'Gallery',
          name: 'Gallery',
          component: Gallery
        },
        {
          path: 'About',
          name: 'About',
          component: About
        },
        {
          path: 'Contact',
          name: 'Contact',
          component: Contact
        },
        {
          path: 'Gallery/Helmets',
          name: 'Helmets',
          component: Helmets
        },
        {
          path: 'Gallery/Vehicles',
          name: 'Vehicles',
          component: Vehicles
        },
        {
          path: 'Gallery/Panels',
          name: 'Panels',
          component: Panels
        },
        {
          path: 'Gallery/Others',
          name: 'Others',
          component: Others
        },
        //catchall 404
        {
          path: ':catchAll(.*)',
          name: 'NotFound',
          component: NotFound
        }
      ],
  }
  ],
  scrollBehavior (to, from, savedPosition) {
    return { top:0 };
  }
});

router.beforeEach((to, from, next) => {
  const lang = to.params.lang;
  
  if(!['en', 'it', 'de'].includes(lang)) {
    return next('it')
  }

  if(i18n.global.locale.value !== lang) {
    i18n.global.locale.value = lang;
  }
  
  return next();
})

export default router
