<template>
  <div class="contact">
    <div class="title">
		<h1>{{ $t('contact.title') }}</h1>
		<h3>{{ $t('contact.subtitle') }}</h3>
	</div>
	<app-popup ref="popup"/>
    <div class="container">
	<div class="front side">
		<svg class="bg" viewBox="-60 80 210 150" xmlns="http://www.w3.org/2000/svg">
			<path class="blur" style="fill:#55697d;fill-opacity:1;stroke:none;stroke-width:.630619;stroke-opacity:1" d="M100.277 79.292s-30.599 55.752-32.235 60.733c-.399 1.168-.8 2.34-1.175 3.57a49.98 49.98 0 0 0-.08 2.78 49.98 49.98 0 0 0 44.352 49.662 49.98 49.98 0 0 1-22.681 5.443 49.98 49.98 0 0 1-41.726-22.466c-.027-.043-.134-.056-.134-.05-.318.138-23.101 43.248-23.101 43.248l158.378.043z"/>
		</svg>
		<div class="content">
			<h1>{{ $t('contactTitle') }}</h1>
			<div class="row">
				<div class="box box1" @click="openPopUp()">
					<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
						<path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
					</svg>
				</div>
				<div class="box box2" @click="call()">
					<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-phone-fill" viewBox="0 0 16 16">
						<path d="M3 2a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V2zm6 11a1 1 0 1 0-2 0 1 1 0 0 0 2 0z"/>
					</svg>
					<p id="telNum" class="prefix">+39</p>
					<p id="telNum">3472458581</p>
				</div>
				<div class="box box3" @click="write()">
					<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-envelope-fill" viewBox="0 0 16 16">
						<path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"/>
					</svg>
					<p id="email">franzdesign@email.it</p>
				</div>
			</div>
			<button class="btn-primary" @click="rotateSx()">{{ $t('contact.write') }}</button>
		</div>
	</div>
	<div class="back side">
		<div class="content">
			<h1>{{ $t('contact.write') }}</h1>
			<form method="POST" id="form" active="../php/_sendmail.php">
				<label>{{ $t('contact.name') }} :</label>
				<input type="text" name="name" required>
				<label>E-mail :</label>
				<input type="email" name="mail" required>
				<label>{{ $t('contact.mex') }} :</label>
				<textarea name="message" required/>
				<button class="btn-outlined-primary" @click.prevent="rotateDx()">{{ $t('buttonBack') }}</button>
				<button type="submit" class="btn-primary send" @click.prevent="sendEmail()">
					<span class="default">{{ $t('contact.send') }}</span>
					<span class="success">{{ $t('contact.sent') }}</span>
					<div class="left"></div>
					<div class="right"></div>
				</button>
			</form>
		</div>
	</div>

</div>
  </div>
</template>

<script>
import Popup from "../components/Popup.vue"
import { gsap } from "gsap";
import { ref } from 'vue'
export default {
	components:{
		'app-popup':Popup
	},
	setup(){
		var popup = ref()
		const rotateSx = () =>{
			gsap.to($('.container'), {
				rotationY: "180deg",
				ease:'power2.inOut'
			});
		}
		const rotateDx = () =>{
			gsap.to($('.container'), {
				rotationY: "0deg",
				ease:'power2.inOut'
			});
			document.getElementById("form").reset()
		}
		const sendEmail = () => {
			
			//send email animation
			document.querySelectorAll('.send').forEach(button => {
			let getVar = variable => getComputedStyle(button).getPropertyValue(variable);
			button.addEventListener('click', e => {

				if(!button.classList.contains('active')) {

					button.classList.add('active');

					gsap.to(button, {
						keyframes: [{
							'--left-wing-first-x': 50,
							'--left-wing-first-y': 100,
							'--right-wing-second-x': 50,
							'--right-wing-second-y': 100,
							duration: .2,
							onComplete() {
								gsap.set(button, {
									backgroundColor: 'transparent',
									'--left-wing-first-y': 0,
									'--left-wing-second-x': 40,
									'--left-wing-second-y': 100,
									'--left-wing-third-x': 0,
									'--left-wing-third-y': 100,
									'--left-body-third-x': 40,
									'--right-wing-first-x': 50,
									'--right-wing-first-y': 0,
									'--right-wing-second-x': 60,
									'--right-wing-second-y': 100,
									'--right-wing-third-x': 100,
									'--right-wing-third-y': 100,
									'--right-body-third-x': 60
								})
							}
						}, {
							'--left-wing-third-x': 20,
							'--left-wing-third-y': 90,
							'--left-wing-second-y': 90,
							'--left-body-third-y': 90,
							'--right-wing-third-x': 80,
							'--right-wing-third-y': 90,
							'--right-body-third-y': 90,
							'--right-wing-second-y': 90,
							duration: .2
						}, {
							'--rotate': 50,
							'--left-wing-third-y': 95,
							'--left-wing-third-x': 27,
							'--right-body-third-x': 45,
							'--right-wing-second-x': 45,
							'--right-wing-third-x': 60,
							'--right-wing-third-y': 83,
							duration: .25
						}, {
							'--rotate': 55,
							'--plane-x': -8,
							'--plane-y': 24,
							duration: .2
						}, {
							'--rotate': 40,
							'--plane-x': 45,
							'--plane-y': -180,
							'--plane-opacity': 0,
							duration: .3,
							onComplete() {
								gsap.to(button, {
									cursor: 'default'
								})
							}
						}]
					})

					gsap.to(button, {
						keyframes: [{
							'--text-opacity': 0,
							'--border-radius': 0,
							'--left-wing-background': getVar('--primary-darkest'),
							'--right-wing-background': getVar('--primary-darkest'),
							duration: .1
						}, {
							'--left-wing-background': getVar('--primary'),
							'--right-wing-background': getVar('--primary'),
							duration: .1
						}, {
							'--left-body-background': getVar('--primary-dark'),
							'--right-body-background': getVar('--primary-darkest'),
							duration: .4
						}, {
							'--success-opacity': 1,
							'--success-scale': 1.5,
							duration: .25,
							delay: .25
						}]
					})

				}

			})

			document.getElementById("form").reset()
		});
		}
		function openPopUp(){ popup.value.openMap()}

		function call(){
			window.open("callto://393472458581");
		}

		function write(){
			window.open("mailto:franzdesign@email.it");
		}

		var footer = document.querySelector("footer"); 
		footer.style.display = "block";
		if(window.matchMedia("(max-width: 720px)").matches)
			footer.style.marginTop = "400px";
		else
			footer.style.marginTop = "50px";
		footer.style.paddingBottom = "50px";
		footer.style.backgroundColor = "none";

		return {rotateSx,rotateDx,sendEmail,openPopUp,call,write,popup}
	}
}
</script>

<style scoped>
* {
	box-sizing: border-box;
}
.contact {
	height: 100vh;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-perspective: 1000px;
	perspective: 1000px;
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
	position: relative;
	font-family: "Montserrat";
	background-image: linear-gradient(180deg, #141928, transparent, transparent, transparent);
}

.container {
	top:100px;
	height: auto;
	width: 700px;
	min-height: 350px;
	border-radius: 20px;
	position: relative;
	-webkit-transition: 1.5s ease-in-out;
	transition: 1.5s ease-in-out;
	transform-style: preserve-3d;
}

.side {
	position: absolute;
	text-align: center;
	width: 100%;
	height: 100%;
	padding: 20px 50px;
	color: #fff;
	transform-style: preserve-3d;
	backface-visibility: hidden;
	border-radius: 20px;
}
.content {
	transform: translatez(70px) scale(0.8);
	line-height: 1.5em;
}
.content h1 {
	position: relative;
}
.content h1:before {
	content: "";
	position: absolute;
	bottom: -20px;
	height: 3px;
	background-color: #4eff00;
	width: 70px;
	left: 50%;
	transform: translateX(-50%);
}
.front {
	z-index: 2;
	background-size: 100vh;
	background-color: #141928;
}
.back {
	transform: rotateY(180deg);
	z-index: 0;
	padding-top: 10px;
	background-color: #141928;
}
form {
	text-align: left;
	display: inline;
}
.back h1 {
	margin: 0;
}
form label,
form input {
	display: block;
}
form input,
form textarea {
	background: #fff5;
    padding: 8px;
    width: 100%;
    color: #fff;
	resize: vertical;
	border-radius:15px;
	border:0;
	filter: drop-shadow( 5px 5px 5px rgba(0, 0, 0, .7));
}
form label {
	margin: 15px 0;
}
::placeholder {
	font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  	color: white;
  	opacity: 0.8;
}

:-ms-input-placeholder {
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  	color: white;
}

::-ms-input-placeholder {
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  	color: white;
}
.send {
    --primary: #4eff00;
    --primary-dark: #55d400;
    --primary-darkest: #338000;
    --text: #fff;
    --text-opacity: 1;
    --success: #fff;
    --success-scale: .2;
    --success-opacity: 0;
    --border-radius: 7;
    --overflow: hidden;
    --rotate: 0;
    --plane-x: 0;
    --plane-y: 0;
    --plane-opacity: 1;
    --left-wing-background: var(--primary);
    --left-wing-first-x: 0;
    --left-wing-first-y: 0;
    --left-wing-second-x: 50;
    --left-wing-second-y: 0;
    --left-wing-third-x: 0;
    --left-wing-third-y: 100;
    --left-body-background: var(--primary);
    --left-body-first-x: 50;
    --left-body-first-y: 0;
    --left-body-second-x: 50;
    --left-body-second-y: 100;
    --left-body-third-x: 0;
    --left-body-third-y: 100;
    --right-wing-background: var(--primary);
    --right-wing-first-x: 50;
    --right-wing-first-y: 0;
    --right-wing-second-x: 100;
    --right-wing-second-y: 0;
    --right-wing-third-x: 100;
    --right-wing-third-y: 100;
    --right-body-background: var(--primary);
    --right-body-first-x: 50;
    --right-body-first-y: 0;
    --right-body-second-x: 50;
    --right-body-second-y: 100;
    --right-body-third-x: 100;
    --right-body-third-y: 100;
	display: inline;
	width: auto;
	margin-left: 28%;
	background: center;
	color: #000;
	cursor: pointer;
    transform: rotate(calc(var(--rotate) * 1deg)) translateZ(0);
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}
.left,
.right {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: var(--plane-opacity);
    transform: translate(calc(var(--plane-x) * 1px), calc(var(--plane-y) * 1px)) translateZ(0);
}
.left:before,.right:before,.left:after,.right:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: calc(var(--border-radius) * 1px);
    transform: translate(var(--x, .4%), var(--y, 0)) translateZ(0);
    z-index: var(--z-index, 2);
    background: var(--background, var(--left-wing-background));
    clip-path: polygon(calc(var(--first-x, var(--left-wing-first-x)) * 1%) calc(var(--first-y, var(--left-wing-first-y)) * 1%), calc(var(--second-x, var(--left-wing-second-x)) * 1%) calc(var(--second-y, var(--left-wing-second-y)) * 1%), calc(var(--third-x, var(--left-wing-third-x)) * 1%) calc(var(--third-y, var(--left-wing-third-y)) * 1%));
}
.left:after {
    --x: 0;
    --z-index: 1;
    --background: var(--left-body-background);
    --first-x: var(--left-body-first-x);
    --first-y: var(--left-body-first-y);
    --second-x: var(--left-body-second-x);
    --second-y: var(--left-body-second-y);
    --third-x: var(--left-body-third-x);
    --third-y: var(--left-body-third-y);
}
.right:before {
    --x: -.4%;
    --z-index: 2;
    --background: var(--right-wing-background);
    --first-x: var(--right-wing-first-x);
    --first-y: var(--right-wing-first-y);
    --second-x: var(--right-wing-second-x);
    --second-y: var(--right-wing-second-y);
    --third-x: var(--right-wing-third-x);
    --third-y: var(--right-wing-third-y);
}
.right:after {
    --x: 0;
    --z-index: 1;
    --background: var(--right-body-background);
    --first-x: var(--right-body-first-x);
    --first-y: var(--right-body-first-y);
    --second-x: var(--right-body-second-x);
    --second-y: var(--right-body-second-y);
    --third-x: var(--right-body-third-x);
    --third-y: var(--right-body-third-y);
}
span {
    display: block;
    position: relative;
    z-index: 4;
    opacity: var(--text-opacity);
}
.success {
    z-index: -1;
    position: absolute;
	border-bottom: 2px solid #4eff00;
    left: 0;
    right: 0;
    top: 8px;
    transform: rotate(calc(var(--rotate) * -1deg)) scale(var(--success-scale)) translateZ(0);
    opacity: var(--success-opacity);
    color: var(--success);
}
.title{
	position: absolute;
	top:100px;
}
h1{
  position: relative;
  margin-top: 0;
  top: 10%;
}
.row{
	margin-top: 10%;
    margin-bottom: 5%;
}
.box{
	display: inline-block;
	margin-left:30px;
    padding-top: 50px;
    width: 150px;
    height: 150px;
    background-color: #fff5;
    border-radius: 20px;
    text-align: center;
}
.box1{
	margin-left: 0;
}
.box1:hover svg{
	fill:#000;
}
.box:hover{
	background-color: #fff8;
	cursor: pointer;
	vertical-align: bottom;
}
.box2:hover svg{
	display:none;
}
#telNum {
	display: none;
	font-size: larger;
}
.prefix {
	font-size: xx-large !important;
}
.box2:hover #telNum{
	display: block;
	color:#000;
	text-align: center;
	transition: all ease 1s;
}
.box2:hover{
    padding-top: 0px;
}
.box3:hover svg{
	display:none;
}
#email {
	display: none;
	font-size: smaller;
}
.box3:hover #email{
	display: block;
	color:#000;
	text-align: center;
	transition: all ease 1s;
}
.bg{
	position: absolute;
    left: 30.35%;
}
@media(max-width: 720px){
    .container{
        top: 320px;
		height: 100%;
		width: 80%;
		min-width: 0px;
    }
    .bg{
        bottom: 0;
		margin-bottom: -2.5%;
    }
	.row{
		margin-top: 10%;
		margin-bottom: 5%;
		display: inline-grid;
	}
	.box1{
		margin-left: auto;
	}
	.box{
		margin-left: 0;
		margin-bottom: inherit;
	}
	.content{
		text-align: -webkit-center;
	}
	.content button{
		display: block;
	}
	.back.side h1{
		white-space: pre;
    	margin-bottom: 100px;
	}
	.back.side textarea{
		height: 200px;
		margin-bottom: 50px;
	}
	.back.side button{
		display: inline-block;
	}
}
</style>