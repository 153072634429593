<template>
  <div>
    <section>
      <div class="bg"></div>
    </section>
    <div class="titleContainer">
      <h1 id="h1">TEXT</h1>
    </div>
    <app-popup ref="popup"/>
    <div class="container">
      <div class="row"></div>
 	  </div>
  </div>
</template>

<script>
import { gsap, ScrollTrigger } from "gsap/all";
import {ref} from "vue"
import i18n from '../i18n'
import Popup from "../components/Popup.vue"
export default {
  components:{
    "app-popup" : Popup
  },
  setup(){
    var popup = ref()
    gsap.registerPlugin(ScrollTrigger);
    const moveDown = (e) =>{
      e.currentTarget.style.marginTop = "0"
      e.currentTarget.style.transition = "all ease-in 0.5s"
    }
    const moveUp = (e) =>{
      e.currentTarget.style.marginTop = "-17%"
      e.currentTarget.style.transition = "all ease-in 0.5s"
    }
    const imageLoad = (category) => {
        var column = 0
        var dataArray = require('../utils/'+category+'.json')
        dataArray.sort((a,b) =>  new Date(b.date) - new Date(a.date));
        var length=dataArray.length
        var div=4
        if(window.matchMedia('(max-width: 600px)').matches)
            div=1
        if(window.matchMedia('(max-width: 800px)').matches)
            div=2
        var rest=Math.trunc(length/div)
        var latestImg=dataArray.slice(0,div*2)
        var oldestImg=dataArray.slice(div*2)
        var j=0, k=0
        for (var i=0; i<length; i++){
        if (i%rest==0 && i<rest*div){
          column++
          var col = document.createElement('div')
          gsap.set(col, {attr:{ class:'column col-'+column},
              textAlign: "-webkit-center",
              flex: "50%",
              padding: "0 4px"
            })
          $('.row').append(col)
        }
        if(window.matchMedia('(max-width: 600px)').matches){
          gsap.set(col,{flex:'100%',maxWidth:'100%'})
        }
        if(window.matchMedia('(max-width: 800px)').matches){
          gsap.set(col,{flex:'50%',maxWidth:'50%'})
        }

        var img = document.createElement('div')
        if((i%rest==0 || i%rest==1) && j<div*2){
            var itemSrc = require("../assets/img/airbrushing/"+category+"/"+latestImg[j].img)
            gsap.set(img, {attr:{ class: category+' img-'+i, id:latestImg[j].img}})
            var months = (new Date().getFullYear()-new Date(latestImg[j].date).getFullYear())*12
            months -= new Date().getMonth()
            months += new Date(latestImg[j].date).getMonth()
            if(months<=18){
                var newImg = document.createElement('div')
                var bookmark = require("../assets/svg/bookmark_new.svg")
                gsap.set(newImg, {
                    attr:{ class: 'newImg-'+i, id:i},
                    width: "10%",
                    height: "80%",
                    float: "left",
                    backgroundSize: "contain",
                    marginTop: "-17%",
                    marginLeft: "5%",
                    backgroundRepeat: "no-repeat",
                    backgroundImage: "url("+bookmark+")"
                    })
                    
                newImg.addEventListener("mouseover",moveDown,false)
                newImg.addEventListener("mouseleave",moveUp,false)
                img.append(newImg)
            }
            j++
        }
        else {
          var itemSrc = require("../assets/img/airbrushing/"+category+"/"+oldestImg[k].img)
          gsap.set(img, {attr:{ class: category+' img-'+i+' lazy', id:oldestImg[k].img, loading: 'lazy'}})
          k++
          gsap.set(img,{translateY: "200px",opacity:0})
          gsap.to(img,{
          translateY:0,
          ease: "none",
          opacity: 1,
          duration: 1,
          scrollTrigger: {
            trigger: ".img-"+(i-1),
            start: "bottom bottom",
            end: "bottom center",
            scrub: 1
           }
          });
        }
        img.addEventListener("click",openPopUp,false)

        $('.col-'+column).append(img)

        gsap.set(img, {
          backgroundImage:'url('+itemSrc+')',
          backgroundSize:'cover',
          backgroundPosition:'center',
          overflow:'hidden',
          cursor: 'pointer',
          width: '100%',
          marginTop: '8px',
          verticalAlign: 'middle',
          height: Math.random()*(600 - 100) + 100,
          borderRadius:20,
          zIndex:1
        })
        
    }
    }

    const scrollAnimation = (category) => {
        document.getElementById("h1").innerHTML = i18n.global.t(category+'Title')
        var sect = document.querySelector("section")
        var bg = sect.querySelector(".bg")
        
        bg.style.backgroundPosition = `50% -100px`;
        if(window.matchMedia('(max-width: 720px)').matches){
          var url = require("../assets/img/backgrounds/"+category+"_mobile.png")
           bg.style.backgroundImage= "url("+url+")"
        }
        else{
          var url = require("../assets/img/backgrounds/"+category+".png")
          bg.style.backgroundImage= "url("+url+")"
        }
        
        gsap.to(bg, {
          backgroundPosition: `50% ${innerHeight / 2}px`,
          ease: "none",
          yPercent: -100,
          scrollTrigger: {
            trigger: "section",
            start: "top top", 
            end: "bottom top",
            scrub: true
          }
        });
        gsap.to("h1",{
          translateY:-150,
          ease: "none",
          opacity: 1,
          scrollTrigger: {
            trigger: ".titleContainer",
            start: "top bottom",
            end: "top top",
            scrub: 1
           }
          });

      }

      function openPopUp(e) { 
          var string = e.currentTarget.className.split(/(\s+)/)
          var cat=string[0]
          popup.value.openImage(cat,e.currentTarget.id)
        }
	  
    return {scrollAnimation, imageLoad, popup, openPopUp}
  }
}
</script>

<style scoped>
section {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
h1{
  font-family: 'Montserrat';
  font-weight: 300;
  font-size: 150px;
  opacity: 0;
  line-height: 1;
  position: absolute;
  z-index: 10;
  cursor: default;
  margin: 0;
  margin-left: 50px;
  text-align: center;
  overflow: hidden;
}
@media(max-width: 720px){
  h1{
    font-size: 80px;
  }
  .titleContainer{
    height: 150px;
  }
}
.titleContainer {
  width:100%;
  height: 400px;
  position: relative;
  display: block;
  text-align: center;
  max-width: 960px;
}
.row {
  display: flex;
  flex-wrap: nowrap;
  padding: 4px;
}
</style>