<template>
    <gsap-tag class="portfolio">
      <gsap-tag class="portfolioBoxes fs"></gsap-tag>
      <gsap-tag class="portfolioClose">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" height="100%" fill="none">
          <circle cx="30" cy="30" r="30" fill="#000" opacity="0.4" />
          <path d="M15,16L45,46 M45,16L15,46" stroke="#000" stroke-width="3.5" opacity="0.5" />
          <path d="M15,15L45,45 M45,15L15,45" stroke="#fff" stroke-width="2" />
        </svg>
      </gsap-tag>
    </gsap-tag>
</template>

<script>
import { gsap } from "gsap";
import { defineComponent } from "vue";
export default defineComponent({
    setup(){
    const load = () => {
    //Carousel js functions
        var currentImg = undefined,
    currentImgProps = {x:0, y:0},
    isZooming = false,
    column = -1,
    mouse = {x:0, y:0},
    delayedPlay;
    gsap.config({ nullTargetWarn: false, trialWarn: false, });
    for (var i=0; i<24; i++){
        
        if (i%8==0) column++;

        var b = document.createElement('gsap-tag');    
        $('.portfolioBoxes').append(b);
        var itemSrc = require("../assets/img/portfolio/"+i+".jpg");

        gsap.set(b, {
          attr:{ id:'b'+i, class:'photoBox pb-col'+column },
          backgroundImage:'url('+itemSrc+')',
          backgroundSize:'cover',
          backgroundPosition:'center',
          overflow:'hidden',
          x:[-150,70,290][column],
          width:400,
          height:640,
          borderRadius:20,
          scale:0.5,
          zIndex:1
        });

        b.tl = gsap.timeline({paused:true, repeat:-1})
          .fromTo(b, {y:[-1150,1600,1600][column], rotation:-0.05}, {duration:[40,35,26][column], y:[1600,-1150,-1150][column], rotation:0.05, ease:'none'})
          .progress(i%8/8)
    }


function pauseBoxes(b){
  var classStr = 'pb-col0';
  if ($(b).hasClass('pb-col1')) classStr = 'pb-col1';
  if ($(b).hasClass('pb-col2')) classStr = 'pb-col2';
  for (var i=0; i<$('.portfolioBoxes').children().length; i++) {
    var b = $('.portfolioBoxes').children()[i];
    if ($(b).hasClass(classStr)) gsap.to(b.tl, {timeScale:0, ease:'sine'});    
  }
}

function playBoxes(){ 
  for (var i=0; i<$('.portfolioBoxes').children().length; i++) { 
    var tl = $('.portfolioBoxes').children()[i].tl;
    tl.play();
    gsap.to(tl, {duration:0.4, timeScale:1, ease:'sine.in', overwrite:true});
  }
}

  var _tl = gsap.timeline({onStart:playBoxes})
      .set('.portfolio',        {perspective:800})
      .set('.photoBox',    {opacity:1, cursor:'pointer'})
      .set('.portfolioBoxes',   {xPercent:-50, width:1200, rotationX:8, rotationY:-8, rotationZ:8})
      .set('.portfolioClose',   {autoAlpha:0, width:60, height:60, left:-30, top:-31, pointerEvents:'none'})
      .fromTo('.portfolio',     {autoAlpha:0},{duration:0.6, ease:'power2.inOut', autoAlpha:1}, 0.2)
  
  $('.photoBox').on('mouseenter', function (e){
    if (currentImg) return;
    if (delayedPlay) delayedPlay.kill();
    pauseBoxes(e.currentTarget);
    var _t = e.currentTarget;
    gsap.to('.photoBox', {duration:0.2,  overwrite:'auto', opacity:function(i,t){return (t==_t)? 1:0.33 }});
    gsap.fromTo(_t,      {zIndex:100}, {duration:0.2, scale:0.62, overwrite:'auto', ease:'power3'});
  });  

  $('.photoBox').on('mouseleave', function (e){
    if (currentImg) return;    
    var _t = e.currentTarget;
    
    if (gsap.getProperty(_t, 'scale')>0.62) delayedPlay = gsap.delayedCall(0.3, playBoxes); // to avoid jump, add delay when mouseout occurs as big image scales back down (not 100% reliable because the scale value sometimes evaluates too late)
    else playBoxes();

    gsap.timeline()
        .set(_t,         {zIndex:1})
        .to(_t,          {duration:0.3, scale:0.5, overwrite:'auto', ease:'expo'}, 0)
        .to('.photoBox', {duration:0.5, opacity:1, ease:'power2.inOut'}, 0);
  });

  $('.photoBox').on('click', function (e){
    if (!isZooming){ //only tween if photoBox isn't currently zooming

      isZooming = true;
      gsap.delayedCall(0.8, function(){ isZooming=false });

      if (currentImg) { 
        gsap.timeline({defaults:{ease:'expo.inOut'}})
            .to('.portfolioClose',   {duration:0.1, autoAlpha:0, overwrite:true}, 0)
            .to('.portfolioBoxes',   {duration:0.5, scale:1, rotationX:8, rotationY:-8, rotationZ:8, overwrite:true}, 0)
            .to('.photoBox',    {duration:0.6, opacity:1, ease:'power4.inOut'}, 0)
            .to(currentImg,     {duration:0.6, width:400, height:640, borderRadius:20, x:currentImgProps.x, y:currentImgProps.y, scale:0.5, rotation:0, zIndex:1, left:'auto'}, 0)
            .to('.infoGallery', {opacity:1},0)
            // .add(playBoxes, 0.8)
        currentImg=undefined;
      }

      else {
        pauseBoxes(e.currentTarget)

        currentImg = e.currentTarget;
        currentImgProps.x = gsap.getProperty(currentImg, 'x');
        currentImgProps.y = gsap.getProperty(currentImg, 'y');
        
        gsap.timeline({defaults:{duration:0.6, ease:'expo.inOut'}})
            .set(currentImg,       {zIndex:100, left:0})
            .to('.infoGallery', {opacity:0},0)
            .fromTo('.portfolioClose',  {x:mouse.x, y:mouse.y, background:'rgba(0,0,0,0)'}, {autoAlpha:1, duration:0.3, ease:'power3.inOut'}, 0)
            .to('.photoBox',       {opacity:0}, 0)
            .to(currentImg,        {width:'100%', height:'100%', borderRadius:0, x:0, top:0, y:0, scale:1, opacity:1}, 0)
            .to('.portfolioBoxes',      {duration:0.5, left:'50%', width:'100%', rotationX:0, rotationY:0, rotationZ:0}, 0.15)
            .to('.portfolioBoxes',      {duration:5, scale:1.06, rotation:0.05, ease:'none'}, 0.65)
      }
    }
  });

  if ( !!('ontouchstart' in window) ) {
    mouse.x = window.innerWidth-50;
    mouse.y = 60;
  }
  else {
    $('.portfolio').on('mousemove', function (e){
      mouse.x = e.x;
      mouse.y = e.layerY;
      if (currentImg) gsap.to('.portfolioClose', {duration:0.1, x:mouse.x, y:mouse.y, overwrite:'auto'});
    });  
  }
}
  return {load};
}
})
</script>

<style>
gsap-tag {
  width:100%;
  height:100%;
  max-height:650px;
  overflow:hidden;
  position: absolute;
}
.portfolio{
    left: 0;
}
</style>