export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "galleryTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gallery"])},
  "aboutTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["about me"])},
  "contactTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contacts"])},
  "helmetsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["helmets"])},
  "vehiclesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vehicles"])},
  "panelsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["walls and panels"])},
  "othersTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["other supports"])},
  "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["view more"])},
  "buttonBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["back"])},
  "galleryText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take a look at some of the works I've done since the 80's. You can find my works organized in 4 sections in corrispondence of the support where they were made."])},
  "helmetsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customization of ski, bike, motorbike or motocross helmets"])},
  "vehiclesText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Airbrushing on motorcycles, cars, trucks and much more"])},
  "panelsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paintings on walls, panels, pictures and signs"])},
  "othersText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decorations on different kind of supports such as canvas, leather and wood"])},
  "about": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["let me introduce myself..."])},
    "firstPart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I'm <text class = 'highlights'> Pietro Franzini </text> and from a very young age I had a particular attraction for drawing and painting. Eager to try new artistic techniques, I got closer and closer to the world of <text class = 'highlights'> airbrushing </text>."])},
    "secondPart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The curiosity for this tool led me to deepen my knowledge about it. Self-taught, I made my first works on a personal level and, over the years, what was initially just a hobby has turned into a real business. I create commissioned projects ranging from decorations for public or private environments to customizations of vehicles and various objects."])},
    "thirdPart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The themes vary according to the customer's choices, but in case of uncertainty, I'll be happy to offer you some solutions. I currently live in <text class = 'highlights'> Grosio </text>(Italy), always available for projects and quotes. You just have to visit my gallery and eventually contact me.<p style='font-size: xx-large;'>Bye!</p>"])}
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you interested?"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call me or send me an email and I'll reply asap!"])},
    "write": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write me now"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "mex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["send"])},
    "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sent"])}
  },
  "footer": {
    "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Franzdesign.eu, property of Franzdesign by Arredo Service of Pietro Franzini - P.IVA 00769360140"])},
    "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copyright ©2022 All rights reserved"])},
    "third": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website created by:"])}
  }
}