<template>
  <app-grid ref="grid" />
  <router-link :to="`/${$i18n.locale}/Gallery/Helmets`">
    <button class="btn-outlined-primary">{{ $t('helmetsTitle') }}</button>
  </router-link>
  <router-link :to="`/${$i18n.locale}/Gallery/Vehicles`">
    <button class="btn-outlined-primary">{{ $t('vehiclesTitle') }}</button>
  </router-link>
  <router-link :to="`/${$i18n.locale}/Gallery/Panels`">
    <button class="btn-outlined-primary">{{ $t('panelsTitle') }}</button>
  </router-link>
</template>

<script>
import {ref, onMounted} from "vue"
import GSAP_ImgGrid from "../components/GSAP_ImgGrid.vue"
export default {
  components:{
    "app-grid": GSAP_ImgGrid
  },
  setup(){
    var grid=ref()
    onMounted(() => {
        grid.value.scrollAnimation("others")
        grid.value.imageLoad("others")
      });
      return {grid}
  }
}
</script>
<style scoped>
button{
  margin: 50px;
  width: 200px;
}
@media(max-width: 720px){
  button{
    margin: 5px;
    width: auto;
  }
}
</style>