export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hallo i18n !!"])},
  "galleryTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["galerie"])},
  "aboutTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["über mich"])},
  "contactTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kontakte"])},
  "helmetsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["helme"])},
  "vehiclesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fahrzeuge"])},
  "panelsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wände und tafeln"])},
  "othersTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["andere stützen"])},
  "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["anzeigen"])},
  "buttonBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zurück"])},
  "galleryText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viel Spaß beim Stöbern in einigen der vielen Arbeiten, die von den 80er Jahren bis heute ausgeführt wurden. Sie finden die Zeichnungen in 4 Abschnitte unterteilt, je nach Träger, auf dem sie erstellt wurden."])},
  "helmetsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalisierung von Ski-, Fahrrad-, Motorrad- oder Motocross-Helmen"])},
  "vehiclesText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Airbrushen an Motorrädern, Autos, LKWs und vielem mehr"])},
  "panelsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wandmalereien, Tafeln, Bilder und Schilder"])},
  "othersText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dekorationen auf verschiedenen Trägern wie Leinwand, Leder und Holz"])},
  "about": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ich stelle mich vor..."])},
    "firstPart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich bin <text class = 'highlights'>Pietro Franzini</text> und hatte schon in jungen Jahren eine besondere Anziehungskraft für das Zeichnen und Malen. Begierig darauf, neue künstlerische Techniken auszuprobieren, kam ich der Welt des <text class = 'highlights'> Airbrush </text> immer näher."])},
    "secondPart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Neugier auf dieses Tool veranlasste mich, mein Wissen darüber zu vertiefen. Als Autodidakt habe ich meine ersten Arbeiten auf privater Ebene gemacht und im Laufe der Jahre hat sich aus dem anfänglichen Hobby ein echtes Geschäft entwickelt. Ich erstelle Auftragsprojekte, die von Dekorationen für öffentliche oder private Umgebungen bis hin zu kundenspezifischen Anpassungen von Fahrzeugen und verschiedenen Objekten reichen."])},
    "thirdPart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Themen variieren je nach Wahl des Kunden, aber im Falle von Unsicherheiten biete ich Ihnen gerne einige Lösungen an. Ich lebe derzeit bei <text class = 'highlights'> Grosio </text>(Italien), immer verfügbar für Projekte und Angebote. Sie müssen nur meine Galerie besuchen und mich eventuell kontaktieren.<p style='font-size: xx-large;'>Tschüss!</p>"])}
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind interessiert?"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rufen Sie an oder schreiben Sie eine E-Mail und ich melde mich schnellstmöglich bei Ihnen zurück!"])},
    "write": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schreiben mir jetzt"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "mex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht "])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["senden"])},
    "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["geschickt"])}
  },
  "footer": {
    "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Franzdesign.eu, Eigentum von Franzdesign - Arredo Service von Pietro Franzini - P.IVA 00769360140"])},
    "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copyright ©2022 - Alle Rechte vorbehalten"])},
    "third": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website erstellt von:"])}
  }
}