<template>
  <nav id="navbar">
    <router-link :to="`/${$i18n.locale}`" class="frn">
      <svg class="franzdesign"
        viewBox="0 0 158.37839 142.96306"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:svg="http://www.w3.org/2000/svg">
          <path d="m 103.08599,2.4091417 c 0,0 -30.599216,55.7517403 -32.235346,60.7332603 -0.39897,1.16783 -0.80066,2.3396 -1.17553,3.56953 a 49.980168,49.980168 0 0 0 -0.0804,2.78007 49.980168,49.980168 0 0 0 44.352396,49.662448 49.980168,49.980168 0 0 1 -22.680966,5.44209 49.980168,49.980168 0 0 1 -41.725442,-22.46539 c -0.02688,-0.0428 -0.133904,-0.0562 -0.133904,-0.0509 -0.318659,0.13925 -23.101363,43.24915 -23.101363,43.24915 l 158.378395,0.0428 z" />
      </svg>

    </router-link>
    <div class="router right">
      <div class="default-menu">
        <router-link :to="`/${$i18n.locale}`">home</router-link>
        <router-link :to="`/${$i18n.locale}/Gallery`">{{ $t('galleryTitle') }}</router-link>
        <router-link :to="`/${$i18n.locale}/About`">{{ $t('aboutTitle') }}</router-link>
        <router-link :to="`/${$i18n.locale}/Contact`">{{ $t('contactTitle') }}</router-link>
        <div class="langSwitcher" :id="`${$i18n.locale}`">
          <text v-for="lang in languages" :key="lang.id" :class="lang.name" @click="setLocale(lang.name)">
            {{lang.text}}
            <div style="color:white !important; display:inline!important">{{lang.sep}}</div>
          </text>
        </div>
      </div>
      <div class="hamburger-menu">
        <input id="menu__toggle" type="checkbox" />
        <label class="menu__btn" for="menu__toggle">
          <span id="hamburger"></span>
        </label>

        <ul class="menu__box">
          <li><router-link class="menu__item" @click="toggleCheckbox()" :to="`/${$i18n.locale}`">home</router-link></li>
          <li><router-link class="menu__item" @click="toggleCheckbox()" :to="`/${$i18n.locale}/Gallery`">{{ $t('galleryTitle') }}</router-link></li>
          <li><router-link class="menu__item" @click="toggleCheckbox()" :to="`/${$i18n.locale}/About`">{{ $t('aboutTitle') }}</router-link></li>
          <li><router-link class="menu__item" @click="toggleCheckbox()" :to="`/${$i18n.locale}/Contact`">{{ $t('contactTitle') }}</router-link></li>
          <li>
            <div class="langSwitcher menu__item" :id="`${$i18n.locale}`">
              <text v-for="lang in languages" :key="lang.id" :class="lang.name" @click="setLocale(lang.name)">
                {{lang.text}}
                <div style="color:white !important; display:inline!important">{{lang.sep}}</div>
              </text>
            </div>
          </li>
          <svg class="mobile-bg" viewBox="0 80 210 150" xmlns="http://www.w3.org/2000/svg">
            <path class="blur" style="fill:#55697d;fill-opacity:0.3;stroke:none;stroke-width:.630619;stroke-opacity:1" d="M100.277 79.292s-30.599 55.752-32.235 60.733c-.399 1.168-.8 2.34-1.175 3.57a49.98 49.98 0 0 0-.08 2.78 49.98 49.98 0 0 0 44.352 49.662 49.98 49.98 0 0 1-22.681 5.443 49.98 49.98 0 0 1-41.726-22.466c-.027-.043-.134-.056-.134-.05-.318.138-23.101 43.248-23.101 43.248l158.378.043z"/>
          </svg>
        </ul>
      </div>
    </div>
  </nav>
  <router-view/>
  <app-footer/>
</template>

<script>
// Imports
import Footer from './components/Footer.vue';
import i18n from './i18n'
export default {
    components: {
      'app-footer': Footer
    },
    setup(){
      const languages = [
      {id: 0, name: "it", text: "IT", sep:" | "},
      {id: 1, name: "en", text: "EN", sep:" | "},
      {id: 2, name: "de", text: "DE", sep:""}
    ]
    return {languages}
    },
    methods: {
      colorLang (locale) {
        var children = document.getElementsByClassName("langSwitcher")[0].children
        
        for(let i=0;i<children.length;i++) 
          if(locale == children[i].className)
            children[i].style.color = "#4eff00"
          else
            children[i].style.color = "#fff"
      },
      setLocale (locale) {
        i18n.global.locale.value = locale
        this.$router.push({
          params: { lang: locale }
        })
        this.colorLang(locale);
      },
      toggleCheckbox() {
        var checkbox = document.getElementById('menu__toggle')
        checkbox.checked = false;
      }
    }
}

window.onscroll = function() {scrollFunction()};
function scrollFunction() {
  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
    
    document.getElementById("navbar").style.background = "black"
    document.getElementById("navbar").style.transition = "0.5s ease"
  } else {
    document.getElementById("navbar").style.background = "none"
  }
}
window.onpageshow = function() {
  var locale = document.getElementsByClassName("langSwitcher")[0].id
  var children = document.getElementsByClassName("langSwitcher")[0].children
  
  for(let i=0;i<children.length;i++) 
    if(locale == children[i].className)
      children[i].style.color = "#4eff00"
    else
      children[i].style.color = "#fff"

  if(window.matchMedia("(max-width: 720px)").matches){
    var checkbox = document.getElementById('menu__toggle')
    document.body.addEventListener('click', function(e) {
      if (e.target.id=="menu__toggle" || e.target.className=="menu__btn" || e.target.id=="hamburger")
        if(checkbox.checked)
          checkbox.checked = false
        else
          checkbox.checked = true
      else
        checkbox.checked = false
    });
  }
}
</script>

<style>
@font-face {
    font-family: "Ace Sans";
    src: url("./assets/fonts/AceSans.otf");
}

.acesans {
    font-family: "Ace Sans";
}
button{
  font-family: "Montserrat";
  text-transform: uppercase;
}
a{
  text-decoration: none;
}
.btn-primary {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  padding: 0.75rem 1.5rem;
  border-radius: 20px;
  background-color: #4eff00;
}

.btn-primary:hover {
  background-color: #60ff1a;
}

.btn-outlined-primary {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  padding: 0.75rem 1.5rem;
  border-radius: 20px;
  background-color: transparent;
  border: 2px solid #4eff00;
  color: white;
}

.btn-outlined-primary:hover {
  background-color: #4eff00;
  color: black;
}
h1 {
  color: white;
  text-shadow: 1px 1px 3px black;
  z-index: 1;
  font-size: 3em;
  font-weight: 400;
  text-transform: uppercase;
}
#app {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
  width: 100%;
}

nav {
  display: flex;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  background: transparent;
  padding: 30px;
  text-align:right;
  z-index: 1000;
  font-family: "Montserrat";
  font-size: smaller;
	color: #fff;
	
}

nav a {
  float: inline-end !important;
  color: white;
  text-decoration: none;
  padding: 10px;
  border-radius: 5px;
  margin-right: 15px;
}

nav a.router-link-exact-active {
  color: #4eff00;
}
nav a.router-link-exact-active:hover, .frn:hover {
  background: none;
}
nav a:hover {
  background: rgba(255,255,255,0.2);
  transition: all 0.5s ease;
}

.frn{
  display: contents;
}

.franzdesign {
  fill:white;
  width: 40px;
  overflow:visible;
  height: auto;
  cursor:pointer;
}
.franzdesign:hover{
  fill:#4eff00;
  transition: all 0.5s ease;
}
body {
  padding-top: 0px!important;
  margin: 0;
  width: auto;
  background-color: black; 
}

.router {
  padding: 10px;
  text-transform: uppercase;
}

.langSwitcher{
  display: inline;
  margin-left: 5vh;
  cursor:pointer;
  color: #fff;
}
.right {
    position: absolute;
    right: 7%;
}
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Slide in */
.highlights {
	overflow: hidden;
	position: relative;
  color: #4eff00;
  cursor: pointer;
  font-size: larger;
}

.highlights::after {
  content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 0.1em;
	background-color: #4eff00;
	opacity: 0;
	transition: opacity 300ms, transform 300ms;
	transform: translate3d(-50%, 0, 0);
}

.highlights:hover::after,
.highlights:focus::after{
  opacity: 1;
	transform: translate3d(0, 0, 0);
}
::selection {
  background: #4eff00;
}

.hamburger-menu{
  display: none;
}
@media(max-width: 720px){
  .hamburger-menu{
    display: flex;
    margin-right: 50px;
  }
  .default-menu{
    display: none;
  }
}
#menu__toggle {
  opacity: 0;
}
#menu__toggle:checked + .menu__btn > span {
  transform: rotate(40deg);
}
#menu__toggle:checked + .menu__btn > span::before {
  top: 0;
  transform: rotate(0deg);
}
#menu__toggle:checked + .menu__btn > span::after {
  top: 2px;
  right: -2px;
  transform: rotate(100deg);
}
#menu__toggle:checked ~ .menu__box {
  right: 0 !important;
}
.menu__btn {
  position: fixed;
  top: 6.5%;
  width: 26px;
  height: 26px;
  cursor: pointer;
  z-index: 1;
}
.menu__btn > span,
.menu__btn > span::before,
.menu__btn > span::after {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #fff;
  transition-duration: .25s;
}
.menu__btn > span::before {
  content: '';
  top: -8px;
}
.menu__btn > span::after {
  content: '';
  top: 8px;
}
.menu__box {
  display: block;
  position: fixed;
  top: 0;
  right: -100%;
  width: 300px;
  height: 100%;
  margin: 0;
  padding: 80px 0;
  list-style: none;
  background-color: #000;
  box-shadow: -2px 2px 6px rgba(225, 225, 225, 0.3);
  transition-duration: .25s;
}
.menu__item {
  display: block;
  padding: 12px 24px;
  font-size: 20px;
  text-decoration: none;
  transition-duration: .25s;
  text-align: initial;
  margin: 0;
}
.mobile-bg{
  margin-top: 80px;
  height: 350px;
}
</style>
