<template>
    <div class="app">

	<div class="cardList">
		<button class="cardList__btn btn btn--left">
			<svg class="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                <path d="M137.4 406.6l-128-127.1C3.125 272.4 0 264.2 0 255.1s3.125-16.38 9.375-22.63l128-127.1c9.156-9.156 22.91-11.9 34.88-6.943S192 115.1 192 128v255.1c0 12.94-7.781 24.62-19.75 29.58S146.5 415.8 137.4 406.6z"/>
            </svg>
		</button>

		<div class="cards__wrapper">
            <div :class='"card "+cat.card+"--card"' v-for="cat in categories" :key="cat.id">
                <div class="card__image">
                    <img :src='require("../assets/img/gallery/"+cat.img)' @click="redirect(cat.id)" alt="" />
                </div>
            </div>
        </div>

		<button class="cardList__btn btn btn--right">
			<svg class="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                <path d="M118.6 105.4l128 127.1C252.9 239.6 256 247.8 256 255.1s-3.125 16.38-9.375 22.63l-128 127.1c-9.156 9.156-22.91 11.9-34.88 6.943S64 396.9 64 383.1V128c0-12.94 7.781-24.62 19.75-29.58S109.5 96.23 118.6 105.4z"/>
            </svg>
		</button>
	</div>

	<div class="infoList">
		<div class="info__wrapper">
			<div :class='"info "+cat.card+"--info"' v-for="cat in categories" :key="cat.id">
				<h1 class="text name" v-if="cat.id==0">{{$t("helmetsTitle")}}</h1>
				<h1 class="text name" v-else-if="cat.id==1">{{$t("vehiclesTitle")}}</h1>
				<h1 class="text name" v-else-if="cat.id==2">{{$t("panelsTitle")}}</h1>
				<h1 class="text name" v-else>{{$t("othersTitle")}}</h1>
				<h4 class="text location">{{ $t('galleryTitle') }}</h4>
				<p class="text description">
                    <text v-if="cat.id==0">{{$t("helmetsText")}}</text>
                    <text v-else-if="cat.id==1">{{$t("vehiclesText")}}</text>
                    <text v-else-if="cat.id==2">{{$t("panelsText")}}</text>
                    <text v-else>{{$t("othersText")}}</text>
                </p>
			</div>
		</div>
	</div>

	<div class="app__bg">
		<div :class='"app__bg__image "+cat.card+"--image"' v-for="cat in categories" :key="cat.id">
			<img :src='require("../assets/img/gallery/"+cat.img)' alt="" />
		</div>
    </div>
</div>

</template>


<script>
import { gsap } from "gsap";
import { reactive, defineComponent } from 'vue'
import { useRouter, useRoute } from 'vue-router'

export default defineComponent({
  setup() {
    const router = useRouter()
    const route = useRoute()
    const categories = reactive([
      {id: 0, img: "caschi.jpg", isActive: false, card:"current"},
      {id: 1, img: "veicoli.jpg", isActive: false, card:"next"},
      {id: 2, img: "pareti_pannelli.jpg", isActive: false, card:"last"},
      {id: 3, img: "altri_supporti.jpg", isActive: false, card:"previous"}
    ])

    const redirect = (id) => {
      let n = "NotFound"
      switch (id) {
          case 0:
            n = "Helmets"
            break;
          case 1:
            n = "Vehicles"
            break;
          case 2:
            n = "Panels"
            break;
          case 3:
            n = "Others"
            break;
          default:
            break;
        }
      router.push({ 
		name: n,
		query: {
			...route.query
		}
      })
    }
    
	const load = () => {

const buttons = {
	prev: document.querySelector(".btn--left"),
	next: document.querySelector(".btn--right"),
};
const cardsContainerEl = document.querySelector(".cards__wrapper");
const appBgContainerEl = document.querySelector(".app__bg");

const cardInfosContainerEl = document.querySelector(".info__wrapper");

buttons.next.addEventListener("click", () => swapCards("right"));

buttons.prev.addEventListener("click", () => swapCards("left"));

function swapCards(direction) {
	const currentCardEl = cardsContainerEl.querySelector(".current--card");
	const previousCardEl = cardsContainerEl.querySelector(".previous--card");
	const nextCardEl = cardsContainerEl.querySelector(".next--card");
    const lastCardEl = cardsContainerEl.querySelector(".last--card");

	const currentBgImageEl = appBgContainerEl.querySelector(".current--image");
	const previousBgImageEl = appBgContainerEl.querySelector(".previous--image");
	const nextBgImageEl = appBgContainerEl.querySelector(".next--image");
    const lastBgImageEl = appBgContainerEl.querySelector(".last--image");

	changeInfo(direction);
	swapCardsClass();

	removeCardEvents(currentCardEl);

	function swapCardsClass() {
		currentCardEl.classList.remove("current--card");
		previousCardEl.classList.remove("previous--card");
		nextCardEl.classList.remove("next--card");
        lastCardEl.classList.remove("last--card");

		currentBgImageEl.classList.remove("current--image");
		previousBgImageEl.classList.remove("previous--image");
		nextBgImageEl.classList.remove("next--image");
        lastBgImageEl.classList.remove("last--image");

		currentCardEl.style.zIndex = "50";
		currentBgImageEl.style.zIndex = "-2";

		if (direction === "right") {
			previousCardEl.style.zIndex = "20";
			nextCardEl.style.zIndex = "30";

			nextBgImageEl.style.zIndex = "-1";

			currentCardEl.classList.add("previous--card");
			previousCardEl.classList.add("last--card");
			nextCardEl.classList.add("current--card");
            lastCardEl.classList.add("next--card");

			currentBgImageEl.classList.add("previous--image");
			previousBgImageEl.classList.add("last--image");
			nextBgImageEl.classList.add("current--image");
            lastBgImageEl.classList.add("next--image");
		} else if (direction === "left") {
			previousCardEl.style.zIndex = "30";
			nextCardEl.style.zIndex = "20";

			previousBgImageEl.style.zIndex = "-1";

			currentCardEl.classList.add("next--card");
			previousCardEl.classList.add("current--card");
			nextCardEl.classList.add("last--card");
            lastCardEl.classList.add("previous--card");

			currentBgImageEl.classList.add("next--image");
			previousBgImageEl.classList.add("current--image");
			nextBgImageEl.classList.add("last--image");
            lastBgImageEl.classList.add("previous--image");
		}
	}
}

function changeInfo(direction) {
	let currentInfoEl = cardInfosContainerEl.querySelector(".current--info");
	let previousInfoEl = cardInfosContainerEl.querySelector(".previous--info");
	let nextInfoEl = cardInfosContainerEl.querySelector(".next--info");
    let lastInfoEl = cardInfosContainerEl.querySelector(".last--info");

	gsap.timeline()
		.to([buttons.prev, buttons.next], {
		duration: 0.2,
		opacity: 0.5,
		pointerEvents: "none",
	})
		.to(
		currentInfoEl.querySelectorAll(".text"),
		{
			duration: 0.4,
			stagger: 0.1,
			translateY: "-120px",
			opacity: 0,
		},
		"-="
	)
		.call(() => {
		swapInfosClass(direction);
	})
		.call(() => initCardEvents())
		.fromTo(
		direction === "right"
		? nextInfoEl.querySelectorAll(".text")
		: previousInfoEl.querySelectorAll(".text"),
		{
			opacity: 0,
			translateY: "40px",
		},
		{
			duration: 0.4,
			stagger: 0.1,
			translateY: "0px",
			opacity: 1,
		}
	)
		.to([buttons.prev, buttons.next], {
		duration: 0.2,
		opacity: 1,
		pointerEvents: "all",
	});

	function swapInfosClass() {
		currentInfoEl.classList.remove("current--info");
		previousInfoEl.classList.remove("previous--info");
		nextInfoEl.classList.remove("next--info");
        lastInfoEl.classList.remove("last--info");

		if (direction === "right") {
			currentInfoEl.classList.add("previous--info");
			nextInfoEl.classList.add("current--info");
			previousInfoEl.classList.add("last--info");
            lastInfoEl.classList.add("next--info");
		} else if (direction === "left") {
			currentInfoEl.classList.add("next--info");
			nextInfoEl.classList.add("last--info");
			previousInfoEl.classList.add("current--info");
            lastInfoEl.classList.add("previous--info");
		}
	}
}

function updateCard(e) {
	const card = e.currentTarget;
	const box = card.getBoundingClientRect();
	const centerPosition = {
		x: box.left + box.width / 2,
		y: box.top + box.height / 2,
	};
	let angle = Math.atan2(e.pageX - centerPosition.x, 0) * (35 / Math.PI);
	gsap.set(card, {
		"--current-card-rotation-offset": `${angle}deg`,
	});
	const currentInfoEl = cardInfosContainerEl.querySelector(".current--info");
	gsap.set(currentInfoEl, {
		rotateY: `${angle}deg`,
	});
}

function resetCardTransforms(e) {
	const card = e.currentTarget;
	const currentInfoEl = cardInfosContainerEl.querySelector(".current--info");
	gsap.set(card, {
		"--current-card-rotation-offset": 0,
	});
	gsap.set(currentInfoEl, {
		rotateY: 0,
	});
}

function initCardEvents() {
	const currentCardEl = cardsContainerEl.querySelector(".current--card");
	currentCardEl.addEventListener("pointermove", updateCard);
	currentCardEl.addEventListener("pointerout", (e) => {
		resetCardTransforms(e);
	});
}

initCardEvents();

function removeCardEvents(card) {
	card.removeEventListener("pointermove", updateCard);
}

function init() {

	let tl = gsap.timeline();

		tl.to(cardsContainerEl.children, {
			delay: 0.15,
			duration: 0.5,
			stagger: {
				ease: "power4.inOut",
				from: "right",
				amount: 0.1,
			},
			"--card-translateY-offset": "0%",
		})
		.to(cardInfosContainerEl.querySelector(".current--info").querySelectorAll(".text"), {
			delay: 0.5,
			duration: 0.4,
			stagger: 0.1,
			opacity: 1,
			translateY: 0,
		})
		.to([buttons.prev, buttons.next], {
			duration: 0.4,
			opacity: 1,
			pointerEvents: "all",
		},
		"-=0.4");
}
	init();}
    
	return {categories, redirect, load};
  }
})

</script>

<style scoped>
* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

body {
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(0, 0, 0, 0.787);
	overflow: hidden;
}
</style>
<style>
:root {
	--card-width: 200px;
	--card-height: 300px;

	--card-transition-duration: 800ms;
	--card-transition-easing: ease;
}
.app {
	position: relative;
	width: 100%;
	height: 100%;
	display: inline-flex;
	justify-content: center;
	align-items: center;
}
.app__bg {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: -5;
	filter: blur(8px);
	pointer-events: none;
	user-select: none;
	overflow: hidden;
}
.app__bg::before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: #000;
	z-index: 1;
	opacity: 0.5;
}
.app__bg__image{
    position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%) translateX(var(--image-translate-offset, 0));
	width: 180%;
	height: 180%;
	transition: transform 1s ease, opacity 15s ease;
	overflow: hidden;
}
.app__bg__image img{
    width: 100%;
	height: 100%;
	object-fit: cover;
}
.current--image {
	opacity: 1;
	--image-translate-offset: 0;
}
.last--image {
	--image-translate-offset: 0;
}
.previous--image, .next--image, .last--image{
	opacity: 0;
}
.previous--image {
	--image-translate-offset: -25%;
}
.next--image {
	--image-translate-offset: 25%;
}

.cardList {
	position: absolute;
	width: calc(3 * var(--card-width));
	height: auto;
}
.cards__wrapper {
	transform: translateY(-100vh);
	position: relative;
	width: 100%;
	height: 100%;
	perspective: 1000px;
}
.cardList__btn {
	padding: 0 !important;
	border: none !important;
	background: none !important;
	--btn-size: 35px;
	width: var(--btn-size);
	height: var(--btn-size);
	position: absolute;
	top: 50%;
	cursor: pointer;
	transform: translateY(-50%);
	z-index: 100;
}
.cardList__btn:focus {
	outline: none !important;
	border: none !important;
}
.btn--left {
	left: -5%;
}
.btn--right {
	right: -5%;
}
.arrow {
	width: 100%;
	height: 100%;
    fill:#fff;
}
.card {
	--card-translateY-offset: 100vh;
	position: absolute;
	border-radius: 20px;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%) translateX(var(--card-translateX-offset))
		translateY(var(--card-translateY-offset)) rotateY(var(--card-rotation-offset))
		scale(var(--card-scale-offset));
	display: inline-block;
	width: var(--card-width);
	height: var(--card-height);
	transition: transform var(--card-transition-duration)
		var(--card-transition-easing);
	user-select: none;
}
.card::before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: #000;
	border-radius: 20px;
	z-index: 1;
	transition: opacity var(--card-transition-duration)
		var(--card-transition-easing);
	opacity: calc(1 - var(--opacity));
}
.card__image {
	position: relative;
	width: 100%;
	height: 100%;
    z-index: 1;
}
.card__image img {
	position: absolute;
	left: 0;
	top: 0;
	border-radius: 20px;
	width: 100%;
	height: 100%;
	object-fit: cover;
}
img:hover{
    cursor: pointer;
}
.current--card {
	--current-card-rotation-offset: 0;
	--card-translateX-offset: 0;
	--card-rotation-offset: var(--current-card-rotation-offset);
	--card-scale-offset: 1.2;
	--opacity: 0.8;
}
.previous--card {
	--card-translateX-offset: calc(-1 * var(--card-width) * 1.1);
	--card-rotation-offset: 25deg;
}
.next--card {
	--card-translateX-offset: calc(var(--card-width) * 1.1);
	--card-rotation-offset: -25deg;
}
.previous--card, .next--card {
	--card-scale-offset: 0.9;
	--opacity: 0.4;
}
.last--card{
    display: none;
}
.infoList {
	position: absolute;
	width: calc(3 * var(--card-width));
	height: var(--card-height);
	pointer-events: none;
}
.info__wrapper {
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: flex-end;
	perspective: 1000px;
	transform-style: preserve-3d;
}
.info {
	margin-bottom: calc(var(--card-height) / 8);
	margin-left: calc(var(--card-width) / 1.5);
	transform: translateZ(2rem);
	transition: transform var(--card-transition-duration)
		var(--card-transition-easing);
}
.description {
	--text-size-offset: 0.065;
	font-weight: 500;
}
.current--info {
	opacity: 1;
	display: block;
}
.previous--info, .next--info, .last--info{
	opacity: 0;
	display: none;
}
.text {
	position: relative;
	font-family: "Montserrat";
	font-size: calc(var(--card-width) * var(--text-size-offset, 0.2));
	white-space: nowrap;
	color: #fff;
	width: fit-content;
}
.name, .location {
	text-transform: uppercase;
}
.location {
	font-weight: 800;
	--mg-left: 40px;
	--text-size-offset: 0.12;
	font-weight: 600;
	margin-left: var(--mg-left);
	margin-bottom: calc(var(--mg-left) / 2);
	padding-bottom: 0.8rem;
}
.location::before,::after {
	content: "";
	position: absolute;
	background: #fff;
	left: 0%;
	transform: translate(calc(-1 * var(--mg-left)), -50%);
}
.location::before {
	top: 50%;
	width: 20px;
	height: 5px;
}
.location::after {
	bottom: 0;
	width: 60px;
	height: 2px;
}
@media only screen and (min-width: 800px) {
	:root {
		--card-width: 250px;
		--card-height: 400px;
	}
}
@media only screen and (max-width: 650px) {
	:root {
		--card-width: 150px;
		--card-height: 350px;
	}
}
</style>
