<template>
	<div class="container centered">
		<app-card ref="cardRef" />
	</div>
</template>

<script>
import GSAP_Card from '../components/GSAP_Card.vue';
import { ref, onMounted } from 'vue'

export default {
	components: {
        'app-card': GSAP_Card
    },
	setup() {
      const cardRef = ref();
      onMounted(() => {
        cardRef.value.load();
      });

	  var footer = document.querySelector("footer");
	  footer.style.display = "block";
	  footer.style.marginTop = "715px";
      footer.style.paddingBottom = "0px";
	  footer.style.backgroundImage ="none";
	  
      return {cardRef};
    }
}
</script>

<style scoped>
.container {
	text-align: center;
	color: white;
	height: 1080px;
	width: 100%;
}

</style>