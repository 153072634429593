<template>
    <div class="modalOverlay" @click="close()"></div>
    <div class="modalContainer">
        <div class="messageWrapper">
            <svg id="close" @click="close()" xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
                <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>
            </svg>
            <svg id="right" @click="next()" xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
            </svg>
            <svg id="left" @click="prev()" xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-caret-left-fill" viewBox="0 0 16 16">
                <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z"/>
            </svg>
            <div class="message">Message</div>
        </div>
    </div>    
</template>
<script>
import { gsap } from "gsap";

export default {
    setup() {
        function openMap() {
            if(window.matchMedia("(max-width: 720px)").matches)
                window.open("https://goo.gl/maps/xk3muhtg3pHGGyWQ9")
            else{
                gsap.set(".messageWrapper", {
                    width: 0,
                    height: 0
                });

                var show = new gsap.timeline();
                show.to(".container", 0.5, { opacity: 0 });
                show.to(".modalOverlay", 0.5, { autoAlpha: 0.75, display: "block" });
                show.to(".modalContainer", 0.5, { display: "flex" });
                show.to(".messageWrapper", 0.5, {
                    width: 800,
                    height: 400,
                    autoAlpha: 1,
                    display: "flex"
                });
                var map = '<div class="mapouter"><div class="gmap_canvas"><iframe width="800" height="400" id="gmap_canvas" src="https://maps.google.com/maps?q=via%20valorsa%2036%20grosio&t=&z=9&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><style>.mapouter{position:relative;text-align:right;height:400px;width:800px;border-radius:20px;}.gmap_canvas {overflow:hidden;background:none!important;height:400px;width:800px;border-radius:20px}</style></div></div>';
                document.querySelector(".message").innerHTML = map;
            }
		}
        
        function openImage(category,thisImg) {
            
			gsap.set(".messageWrapper", {
				width: 0,
				height: 0
			});

			var show = new gsap.timeline();
			show.to(".modalOverlay", 0.5, { autoAlpha: 0.75, display: "block" });
            show.to(".modalContainer", 0.5, { display: "flex", height: document.querySelector(".container").scrollHeight });
			show.to(".messageWrapper", 0.5, {
				width: 1000,
				height: 600,
				autoAlpha: 1,
                display: "flex"
			});
            var itemSrc = require("../assets/img/airbrushing/"+category+"/"+thisImg)
            var id = thisImg.split(".")
			var img = '<div class="imgView '+category+'" id='+id[0]+' style="background-image: url('+itemSrc+');"></div>';
			document.querySelector(".message").innerHTML = img;

            var data = require('../utils/'+category+'.json')
            for(var i=0;i<data.length;i++){
                if(data[i].img==thisImg)
                    var obj = data[i]
            }

            if(obj.countImg>0){
                document.getElementById("right").style.display = "block"
                document.getElementById("left").style.display = "block"
            }
            else{
                document.getElementById("right").style.display = "none"
                document.getElementById("left").style.display = "none"
            }
		}

		function close(){
			var hide = new gsap.timeline();
			hide.to(".container", 0.1, { opacity: 1 });
			hide.to(".modalOverlay", 0.1, { autoAlpha: 0, display: "none" });
            hide.to(".modalContainer", 0.1, { display: "none" });
			hide.to(".messageWrapper", 0.1, {
				width: 0,
				height: 0,
				autoAlpha: 0,
                display: "none"
			});
			document.body.style.overflow = "auto";
		}

        function next(){
            var img = document.getElementsByClassName("imgView")[0]
            var category = img.className.split(" ")[1]
            var data = require('../utils/'+category+'.json')

            if(img.id.includes('_')){
                var main = img.id.split("_")[0] 
                var num = img.id.split("_")[1].split(".")[0]
                for(var i=0;i<data.length;i++){
                    if(data[i].img==main+".jpg")
                        var obj = data[i]
                }
                var max=obj.countImg
                if(num<max){
                    // x_1 => x_2 => x_max
                    num++
                    var itemSrc = require("../assets/img/airbrushing/"+category+"/"+main+"_"+num+".jpg")
                    img.id = main+"_"+num
                    img.style.backgroundImage = "url("+itemSrc+")"
                }
                else{
                    // x_max => x
                    var itemSrc = require("../assets/img/airbrushing/"+category+"/"+main+".jpg")
                    img.id = main
                    img.style.backgroundImage = "url("+itemSrc+")"
                }
            }
            else{
                // x => x_1
                var itemSrc = require("../assets/img/airbrushing/"+category+"/"+img.id+"_1.jpg")
                img.id = img.id+"_1"
                img.style.backgroundImage = "url("+itemSrc+")"
            }
        }

        function prev(){
            var img = document.getElementsByClassName("imgView")[0]
            var category = img.className.split(" ")[1]
            var data = require('../utils/'+category+'.json')

            if(img.id.includes('_')){
                var main = img.id.split("_")[0] 
                var num = img.id.split("_")[1].split(".")[0]
                
                if(num>1){
                    // x_max => x_2 => x_1
                    num--
                    var itemSrc = require("../assets/img/airbrushing/"+category+"/"+main+"_"+num+".jpg")
                    img.id = main+"_"+num
                    img.style.backgroundImage = "url("+itemSrc+")"
                }
                else{
                    // x_1 => x
                    var itemSrc = require("../assets/img/airbrushing/"+category+"/"+main+".jpg")
                    img.id = main
                    img.style.backgroundImage = "url("+itemSrc+")"
                }
            }
            else{
                // x => x_max
                for(var i=0;i<data.length;i++){
                    if(data[i].img==img.id+".jpg")
                        var obj = data[i]
                }
                var max=obj.countImg
                var itemSrc = require("../assets/img/airbrushing/"+category+"/"+img.id+"_"+max+".jpg")
                img.id = img.id+"_"+max
                img.style.backgroundImage = "url("+itemSrc+")"
            }
        }
        return {openMap,openImage,close,next,prev}
    }
}
</script>
<style>
.modalOverlay {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  text-align: center;
  z-index: 11;
  cursor: pointer;
  opacity: 0;
}

.modalContainer {
  display: none;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: absolute;
}

.messageWrapper {
  display: none;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 800px;
  height: 400px;
  background-color: transparent;
  position: sticky;
  z-index: 12;
  opacity: 0;
  top:15%;
  border-radius: 20px;
}

.message {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  color: #000;
  position: relative;
}
#close{
    top: 10px;
    right: 10px;
}
#right{
    display: none;
    top: 285px;
    right: 10px;
}
#left{
    display: none;
    top: 285px;
    left: 10px;
}
#close:hover,#right,#left{
	border: 2px solid;
    border-radius: inherit;
    padding: 3px;
}
#close:hover{
    top: 6px;
    right: 6px;
}
#close:active,#right:hover,#left:hover{
	color:#000 !important;
	border-color: #fff;
	background-color: #fff;
}
#close,#left,#right{
    color:#fff;
    position: absolute;
    z-index: inherit;
    cursor: pointer;
}
.imgView{
    background-size:contain;
    background-position:center;
    background-repeat: no-repeat;
    width:1000px;
    height:600px;
    border-radius:20px;
}
@media(max-width: 1000px){
  .imgView, .messageWrapper{
    width:600px !important;
    height: 400px !important;
  }
  .messageWrapper{
      top: 30% !important;
  }
}
@media(max-width: 600px){
  .imgView, .messageWrapper{
      width: 400px !important;
      height: 500px !important;
  }
  .messageWrapper{
      top:20% !important;
  }
}
</style>
